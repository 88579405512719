import axios from "axios";
import BaseEntityModel from "./../../general/BaseEntityModel";
import UserValidSettingsFilter from "./UserValidSettingsFilter";
import UserValidSettingImg from "@/assets/images/UserValidSettings.svg";
import { PAGE_SIZE, STATUS, BASE_URL } from "@/utils/constants";
import i18n from "@/i18n";
import store from "@/store";

export default class UserValidSetting extends BaseEntityModel {
  defaultImg = UserValidSettingImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.userValidSettingToken = "";
    this.dailyCode = "";
    this.fullCode = "";
    this.userTypeToken = "";
    this.userTypeNameCurrent = "";
    this.userTypeNameAr = "";
    this.userTypeNameEn = "";
    this.userTypeNameUnd = "";
    this.userValidSettingNameCurrent = "";
    this.userValidSettingNameEn = "";
    this.userValidSettingNameAr = "";
    this.userValidSettingNameUnd = "";
    this.userValidSettingDescriptionCurrent = "";
    this.userValidSettingDescriptionAr = "";
    this.userValidSettingDescriptionEn = "";
    this.userValidSettingDescriptionUnd = "";
    this.userValidSettingImagePath = "";
    this.userValidSettingNotes = "";
    this.userValidSettingActiveStatus = "";
    this.userValidSettingArchiveStatus = "";
    this.requiredUserImageStatus = "";
    this.requiredUserFullCodeStatus = "";
    this.requiredUserEmailStatus = "";
    this.requiredUserPhoneStatus = "";
    this.requiredUserUserNameStatus = "";
    this.requiredUserNationalIDStatus = "";
    this.requiredUserExpiryDateNationalIDStatus = "";
    this.requiredUserNotesStatus = "";
  }
  fillData(data) {
    this.fillDataBaseEntityModelBaseEntityModel(data);
    this.userValidSettingToken = data.userValidSettingToken;
    this.dailyCode = data.dailyCode;
    this.fullCode = data.fullCode;
    this.userTypeToken = data.userTypeToken;
    this.userTypeNameCurrent = data.userTypeNameCurrent;
    this.userTypeNameAr = data.userTypeNameAr;
    this.userTypeNameEn = data.userTypeNameEn;
    this.userTypeNameUnd = data.userTypeNameUnd;
    this.userValidSettingNameCurrent = data.userValidSettingNameCurrent;
    this.userValidSettingNameEn = data.userValidSettingNameEn;
    this.userValidSettingNameAr = data.userValidSettingNameAr;
    this.userValidSettingNameUnd = data.userValidSettingNameUnd;
    this.userValidSettingDescriptionCurrent =
      data.userValidSettingDescriptionCurrent;
    this.userValidSettingDescriptionAr = data.userValidSettingDescriptionAr;
    this.userValidSettingDescriptionEn = data.userValidSettingDescriptionEn;
    this.userValidSettingDescriptionUnd = data.userValidSettingDescriptionUnd;
    this.userValidSettingImagePath = data.userValidSettingImagePath;
    this.userValidSettingNotes = data.userValidSettingNotes;
    this.userValidSettingActiveStatus = data.userValidSettingActiveStatus;
    this.userValidSettingArchiveStatus = data.userValidSettingArchiveStatus;
    this.requiredUserImageStatus = data.requiredUserImageStatus;
    this.requiredUserFullCodeStatus = data.requiredUserFullCodeStatus;
    this.requiredUserEmailStatus = data.requiredUserEmailStatus;
    this.requiredUserPhoneStatus = data.requiredUserPhoneStatus;
    this.requiredUserUserNameStatus = data.requiredUserUserNameStatus;
    this.requiredUserNationalIDStatus = data.requiredUserNationalIDStatus;
    this.requiredUserExpiryDateNationalIDStatus =
      data.requiredUserExpiryDateNationalIDStatus;
    this.requiredUserNotesStatus = data.requiredUserNotesStatus;
  }

  async getAllUserValidSettings(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    return await axios.get(
      `/api/UserValidSettings/GetAllUserValidSettings?language=${language}&userAuthorizeToken=${userAuthorizeToken}&paginationStatus=true&pageSize=${PAGE_SIZE}&page=${pagination.selfPage}&filterStatus=true&userTypeToken=${filterData.userTypeToken}&textSearch=${filterData.textSearch}`
    );
  }

  async getUserValidSettingDialog(
    language,
    userAuthorizeToken,
    filterData = new UserValidSettingsFilter()
  ) {
    let options = [];
    try {
      let response = await axios.get(
        `/api/UserValidSettings/GetUserValidSettingDialog?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      const itemsData = response.data.itemsData;
      if (response.data.status == STATUS.SUCCESS) {
        for (let item in itemsData) {
          options.push({
            value: itemsData[item]["itemToken"],
            text: itemsData[item]["itemName"],
            image: itemsData[item]["itemImagePath"]
              ? BASE_URL + itemsData[item]["itemImagePath"]
              : UserValidSettingImg,
          });
        }
      } else if (response.data.status == STATUS.INVALID_TOKEN) {
        store.dispatch("logoutUser");
      } else {
        options.push({
          value: "",
          text: response.data.msg,
          image: UserValidSettingImg,
        });
      }
    } catch (error) {
      options.push({
        value: "",
        text: i18n.t("errorCatch"),
        image: UserValidSettingImg,
      });
    }
    return options;
  }

  async getUserValidSettingDetails(language, userAuthorizeToken) {
    return await axios.get(
      `/api/UserValidSettings/GetUserValidSettingDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&token=${this.userValidSettingToken}`
    );
  }

  async addOrUpdateUserValidSetting(language, userAuthorizeToken) {
    var formData = new FormData();
    formData.append("mediaFile", this.mediaFile);
    formData.append("language", language);
    formData.append("userAuthorizeToken", userAuthorizeToken);
    formData.append("userValidSettingToken", this.userValidSettingToken);
    formData.append("fullCode", this.fullCode);
    formData.append("userTypeToken", this.userTypeToken);
    formData.append("userValidSettingNameEn", this.userValidSettingNameEn);
    formData.append("userValidSettingNameAr", this.userValidSettingNameAr);
    formData.append("userValidSettingNameUnd", this.userValidSettingNameUnd);
    formData.append(
      "userValidSettingDescriptionEn",
      this.userValidSettingDescriptionEn
    );
    formData.append(
      "userValidSettingDescriptionAr",
      this.userValidSettingDescriptionAr
    );
    formData.append(
      "userValidSettingDescriptionUnd",
      this.userValidSettingDescriptionUnd
    );
    formData.append("userValidSettingNotes", this.userValidSettingNotes);
    formData.append(
      "userValidSettingActiveStatus",
      this.userValidSettingActiveStatus
    );
    formData.append("requiredUserImageStatus", this.requiredUserImageStatus);
    formData.append(
      "requiredUserFullCodeStatus",
      this.requiredUserFullCodeStatus
    );
    formData.append("requiredUserEmailStatus", this.requiredUserEmailStatus);
    formData.append("requiredUserPhoneStatus", this.requiredUserPhoneStatus);
    formData.append(
      "requiredUserUserNameStatus",
      this.requiredUserUserNameStatus
    );
    formData.append(
      "requiredUserNationalIDStatus",
      this.requiredUserNationalIDStatus
    );
    formData.append(
      "requiredUserExpiryDateNationalIDStatus",
      this.requiredUserExpiryDateNationalIDStatus
    );
    formData.append("requiredUserNotesStatus", this.requiredUserNotesStatus);

    if (
      this.userValidSettingToken == "" ||
      this.userValidSettingToken == undefined
    ) {
      return await axios.post(
        `/api/UserValidSettings/AddUserValidSetting`,
        formData
      );
    } else {
      return await axios.post(
        `/api/UserValidSettings/UpdateUserValidSetting`,
        formData
      );
    }
  }

  async archiveUserValidSetting(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      token: this.userValidSettingToken,
      archiveStatus: true,
    };

    return await axios.post(
      `/api/UserValidSettings/ArchiveUserValidSetting`,
      data
    );
  }
}
