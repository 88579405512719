<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          alt="Image"
          @click="selectImage"
        ></div>
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'fullCode'"
            :value="userValidSetting.fullCode"
            v-on:changeValue="userValidSetting.fullCode = $event"
            :title="$t('UserValidSettings.code')"
            :imgName="'code.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'userTypeToken'"
            :value="userValidSetting.userTypeToken"
            :options="userTypeTokenOptions"
            v-on:changeValue="userValidSetting.userTypeToken = $event"
            :title="$t('users.selectType')"
            :imgName="'type.svg'"
          />

          <CustomInput
            :className="'col-md-6'"
            :id="'userValidSettingNameAr'"
            :value="userValidSetting.userValidSettingNameAr"
            v-on:changeValue="userValidSetting.userValidSettingNameAr = $event"
            :title="$t('UserValidSettings.nameAr')"
            :imgName="'establishment-role.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'userValidSettingNameEn'"
            :value="userValidSetting.userValidSettingNameEn"
            v-on:changeValue="userValidSetting.userValidSettingNameEn = $event"
            :title="$t('UserValidSettings.nameEn')"
            :imgName="'establishment-role.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'userValidSettingDescriptionAr'"
            :value="userValidSetting.userValidSettingDescriptionAr"
            v-on:changeValue="
              userValidSetting.userValidSettingDescriptionAr = $event
            "
            :title="$t('UserValidSettings.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'userValidSettingDescriptionEn'"
            :value="userValidSetting.userValidSettingDescriptionEn"
            v-on:changeValue="
              userValidSetting.userValidSettingDescriptionEn = $event
            "
            :title="$t('UserValidSettings.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'userValidSettingNotes'"
            :value="userValidSetting.userValidSettingNotes"
            v-on:changeValue="userValidSetting.userValidSettingNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="userValidSetting.userValidSettingActiveStatus"
            v-on:changeValue="
              userValidSetting.userValidSettingActiveStatus = $event
            "
            :title="$t('UserValidSettings.activeStatus')"
          />
        </div>
      </div>

      <div class="my-card">
        <span class="my-card-title">{{
          $t("UserValidSettings.requiredFields")
        }}</span>

        <div class="row">
          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserImageStatus"
            v-on:changeValue="userValidSetting.requiredUserImageStatus = $event"
            :title="$t('UserValidSettings.UserImage')"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserFullCodeStatus"
            v-on:changeValue="
              userValidSetting.requiredUserFullCodeStatus = $event
            "
            :title="$t('UserValidSettings.UserFullCode')"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserEmailStatus"
            v-on:changeValue="userValidSetting.requiredUserEmailStatus = $event"
            :title="$t('UserValidSettings.UserEmail')"
          />

          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserPhoneStatus"
            v-on:changeValue="userValidSetting.requiredUserPhoneStatus = $event"
            :title="$t('UserValidSettings.UserPhone')"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserNationalIDStatus"
            v-on:changeValue="
              userValidSetting.requiredUserNationalIDStatus = $event
            "
            :title="$t('UserValidSettings.UserNationalID')"
          />
          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserExpiryDateNationalIDStatus"
            v-on:changeValue="
              userValidSetting.requiredUserExpiryDateNationalIDStatus = $event
            "
            :title="$t('UserValidSettings.UserExpiryDateNationalID')"
          />

          <CustomCheckbox
            :className="'col-md-6 col-lg-4'"
            :value="userValidSetting.requiredUserNotesStatus"
            v-on:changeValue="userValidSetting.requiredUserNotesStatus = $event"
            :title="$t('UserValidSettings.UserNotes')"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateUserValidSetting"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'UserValidSettings' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import { BASE_URL } from "@/utils/constants";
import { setDataMultiLang } from "@/utils/functions";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomInput,
    CustomSelectBox,
    CustomCheckbox,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: "",
      userTypeTokenOptions: [],
    };
  },
  props: ["userValidSetting", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.userValidSetting.mediaFile = file[0];
      }
    },
    async addOrUpdateUserValidSetting() {
      this.$emit("addOrUpdateUserValidSetting");
    },
    async getDialogOfUserTypes() {
      this.isLoading = true;
      this.userTypeTokenOptions = [];
      this.userTypeTokenOptions.push({
        value: "",
        text: this.$t("users.selectType"),
      });
      let genders = this.constantsListsData.listUserType;
      for (let item in genders) {
        this.userTypeTokenOptions.push({
          value: genders[item]["itemToken"],
          text: setDataMultiLang(
            this.language,
            genders[item]["itemNameAr"],
            genders[item]["itemNameEn"]
          ),
        });
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.imageSrc = this.userValidSetting.userValidSettingImagePath
      ? BASE_URL + this.userValidSetting.userValidSettingImagePath
      : this.userValidSetting.defaultImg;
    this.getDialogOfUserTypes();
  },
};
</script>

<style lang="scss"></style>
